import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

type CheckboxCalendarProps = {
    name: string;
    selectedCalendars: string[],
    setSelectedCalendars: React.Dispatch<React.SetStateAction<string[]>>
}

export const CheckboxCalendar: React.FC<CheckboxCalendarProps> = ({
    name,
    selectedCalendars = [],
    setSelectedCalendars,
}) => (
    <FormControlLabel
        control={<Checkbox />}
        label={name}
        checked={selectedCalendars.includes(name)}
        onChange={(e, checked) => {
            if (checked) {
                setSelectedCalendars((curr) => [...curr, name]);
            } else {
                setSelectedCalendars((curr) =>
                    curr.filter((value) => name !== value)
                );
            }
        }}
    />
);
