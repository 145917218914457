import React from 'react';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';

export const InputField = ({
    id,
    label,
    variant = 'outlined',
    value,
    onChange,
    size = 'small',
    ...inputProps
}) => (
    <FormControl variant={variant} size={size}>
        <InputLabel id={label}>{label}</InputLabel>
        <OutlinedInput
            id={id}
            value={value}
            onChange={onChange}
            label={label}
            {...inputProps}
        />
    </FormControl>
);
