import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

var firebaseConfig = {
    apiKey: 'AIzaSyC3uQy5HnGoz91uaWO9_S6jcvPfDta0v5w',
    authDomain: 'stubby-6bd8f.firebaseapp.com',
    projectId: 'stubby-6bd8f',
    storageBucket: 'stubby-6bd8f.appspot.com',
    messagingSenderId: '1048516102192',
    appId: '1:1048516102192:web:a0609b5f0c2df30cd585f5',
    measurementId: 'G-BV32DJHG1Z',
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
