import React, { createContext, useState } from 'react';
import { ConfirmationModal, AlertModal } from '../modals';

export const ModalContext = createContext({});

export const ModalProvider = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState();

    const showAlert = (props) => {
        setIsModalOpen(true);
        setModalContent(<AlertModal {...props} />);
    };

    const showConfirm = (props) => {
        setIsModalOpen(true);
        setModalContent(<ConfirmationModal {...props} />);
    };

    const showModal = (modal) => {
        setIsModalOpen(true);
        setModalContent(modal);
    };

    return (
        <ModalContext.Provider
            value={{
                isModalOpen,
                setIsModalOpen,
                showAlert,
                showConfirm,
                showModal,
            }}
        >
            {children}
            {isModalOpen && modalContent}
        </ModalContext.Provider>
    );
};
