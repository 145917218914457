import React, { useContext, useMemo } from 'react';
import { ResponsiveCalendar } from '@nivo/calendar';
import { Paper, useTheme } from '@mui/material';
import { isUndefined } from 'lodash';
import { interpolateColors, hexToRgb } from '../../../util/colors';
import { BreakpointContext } from '../../contexts/BreakpointContext';

export const CalendarChart = ({
    data = [],
    borderColor,
    emptyColor = '#161b22',
    onClick,
    colors,
    unit,
}) => {
    const { breakpoint } = useContext(BreakpointContext);
    const { palette } = useTheme();
    const interpolatedColors = interpolateColors(
        hexToRgb(palette.primary.main),
        hexToRgb(palette.secondary.main),
        10
    );

    const direction = useMemo(
        () => (breakpoint === 'mobile' ? 'vertical' : 'horizontal'),
        [breakpoint]
    );

    return (
        <ResponsiveCalendar
            theme={{ textColor: '#fff' }}
            data={data}
            direction={direction}
            from={new Date(new Date().getFullYear(), 0, 1)}
            to={new Date(new Date().getFullYear(), 11, 31)}
            emptyColor={emptyColor}
            colors={interpolatedColors || colors}
            margin={{ top: 0, right: 10, bottom: 0, left: 40 }}
            yearSpacing={40}
            monthBorderColor={borderColor}
            dayBorderWidth={2}
            dayBorderColor={borderColor}
            onClick={onClick}
            tooltip={({ color, day, value, date }) =>
                !isUndefined(value) ? (
                    <Paper elevation={7} style={{ padding: '15px' }} align="left">
                        <div style={{ color }}>{day}</div>
                        <div>value: {unit ? `${value} ${unit}` : value}</div>
                    </Paper>
                ) : null
            }
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'row',
                    translateY: 36,
                    itemCount: 4,
                    itemWidth: 42,
                    itemHeight: 36,
                    itemsSpacing: 14,
                    itemDirection: 'right-to-left',
                },
            ]}
        />
    );
};
