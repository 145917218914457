import React, { useEffect, useState, useCallback } from 'react';
import update from 'immutability-helper';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// import styles from './Sidebar.module.scss';
import { Card } from './Draggable/Card';
import { cloneDeep, isEqual } from 'lodash';
import { CheckboxCalendar } from './CheckboxCalendar';
import { CustomDragLayer } from './Draggable/CustomDragLayer';

type CalendarDef = string | {
    name: string;
    items: string[];
}

type DraggableCalendarSelectProps = {
    selectedCalendars: string[];
    setSelectedCalendars: React.Dispatch<React.SetStateAction<string[]>>;
    calendarDefs: CalendarDef[];
    updateUserDoc: (update: Record<string, any>) => void
}

export const DraggableCalendarSelect: React.FC<DraggableCalendarSelectProps> = ({
    selectedCalendars,
    setSelectedCalendars,
    calendarDefs: calendarDefsProp = [],
    updateUserDoc,
}) => {
    const [expanded, setExpanded] = useState<string | boolean>(false);

    const handleChange = (name: string) => (event: React.SyntheticEvent<Element, Event>, isExpanded: boolean) => {
        setExpanded(isExpanded ? name : false);
    };

    const [calendarDefs, setCalendarDefs] = useState<CalendarDef[]>([]);

    useEffect(() => {
        if (!isEqual(calendarDefs, calendarDefsProp)) {
            setCalendarDefs(cloneDeep(calendarDefsProp));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calendarDefsProp]);

    const onDrop = useCallback(() => {
        if (!isEqual(calendarDefsProp, calendarDefs)) {
            console.log('perform update', calendarDefs)
            updateUserDoc({ calendarDefs });
        }
    }, [calendarDefsProp, calendarDefs, updateUserDoc]);

    const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
        setCalendarDefs((prevCards) => {
            return update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex]],
                ],
            });
        });
    }, []);

    const renderCard = useCallback(
        (card: CalendarDef, index: number) => {
            const name = typeof card === 'string' ? card : card.name;
            return (
                <Card
                    id={name}
                    key={name}
                    itemType={typeof card === 'string' ? 'single' : 'group'}
                    index={index}
                    moveCard={moveCard}
                    onDrop={onDrop}
                >
                    {(typeof card === 'string') ? (
                                <CheckboxCalendar
                                    name={name}
                                    selectedCalendars={selectedCalendars}
                                    setSelectedCalendars={setSelectedCalendars}
                                />
                            )
                        :  (
                            <div>
                                <Accordion
                                    style={{ marginBottom: '6px' }}
                                    expanded={expanded === name}
                                    onChange={handleChange(name)}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography
                                            sx={{
                                                width: '33%',
                                                flexShrink: 0,
                                            }}
                                        >
                                            {name}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {
                                            card.items.map((item) => (
                                                <CheckboxCalendar
                                                    key={item}
                                                    name={item}
                                                    selectedCalendars={
                                                        selectedCalendars
                                                    }
                                                    setSelectedCalendars={
                                                        setSelectedCalendars
                                                    }
                                                />
                                            ))}
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        )
                    }
                </Card>
            );
        },
        [expanded, selectedCalendars, onDrop, moveCard, setSelectedCalendars]
    );

    return (
        <DndProvider backend={HTML5Backend}>
            <CustomDragLayer />
            <div>{calendarDefs.map((def, index) => renderCard(def, index))}</div>
        </DndProvider>
    );
};
