import React from 'react';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { IconButton, Drawer } from '@mui/material';

import styles from '../Dashboard.module.scss';
import { AddNewCalendarButton } from '../AddNewCalendar/AddNewCalendar';
import { DraggableCalendarSelect } from './DraggableCalendarSelect';

export const Sidebar = ({
    isDrawerOpen,
    breakpoint,
    drawerWidth,
    userToShow,
    onClickMenu,
    selectedCalendars,
    setSelectedCalendars,
    setCalendars,
    updateUserDoc,
    currentUserInfo,
}) => {
    return (
        <Drawer
            open={isDrawerOpen}
            variant={breakpoint === 'mobile' ? 'temporary' : 'persistent'}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            anchor="left"
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '10px',
                }}
            >
                <IconButton
                    aria-label="close drawer"
                    aria-controls="menu-drawer"
                    aria-haspopup="true"
                    onClick={onClickMenu}
                    color="inherit"
                >
                    <ChevronLeft />
                </IconButton>
            </div>

            <div className={styles.sidebarGrid}>
                <AddNewCalendarButton
                    uid={userToShow}
                    setCalendars={setCalendars}
                    setSelectedCalendars={setSelectedCalendars}
                    updateUserDoc={updateUserDoc}
                    calendarDefs={currentUserInfo.calendarDefs}
                />
                <DraggableCalendarSelect
                    selectedCalendars={selectedCalendars}
                    setSelectedCalendars={setSelectedCalendars}
                    calendarDefs={currentUserInfo.calendarDefs}
                    updateUserDoc={updateUserDoc}
                />
            </div>
        </Drawer>
    );
};
