import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { auth } from '../../firebase';
import { TextField, Button } from '@mui/material';
import styles from './SignIn.module.scss';

export const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const history = useHistory();

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                history.push('/dashboard');
            }
        });
    }, [history]);

    const login = () => {
        auth.signInWithEmailAndPassword(email, password).catch((error) => {
            // eslint-disable-next-line no-alert
            alert(error.message);
        });
    };

    return (
        <div className={styles.wrapper}>
            <main>
                <div className={styles.container}>
                    <TextField
                        label="Email"
                        id="email"
                        name="email"
                        type="text"
                        placeholder="Email"
                        value={email}
                        variant="outlined"
                        onChange={(e) => setEmail(e.target.value)}
                        size="small"
                    />
                    <TextField
                        label="Password"
                        id="password"
                        name="password"
                        type="Password"
                        placeholder="Password"
                        value={password}
                        variant="outlined"
                        onChange={(e) => setPassword(e.target.value)}
                        size="small"
                    />

                    <Button color="primary" className="login_btn" onClick={login}>
                        Login
                    </Button>
                </div>
            </main>
        </div>
    );
};
