import React, { useContext } from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    MenuItem,
    Menu,
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import styles from './Header.module.scss';
import { useHistory } from 'react-router';
import { UserContext } from '../contexts/UserContext';
import { auth } from '../../firebase';

export const Header = ({ onClickMenu, ...props }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const history = useHistory();

    const { userInfo } = useContext(UserContext);

    const handleMenu = () => {
        onClickMenu();
    };

    const handleUserMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleClose();
        auth.signOut().then(() => {
            history.push('/');
        });
    };

    return (
        <AppBar position="sticky" {...props}>
            <Toolbar>
                {onClickMenu && (
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >
                        <MenuIcon />
                    </IconButton>
                )}
                <Typography variant="h6" className={styles.title}>
                    {userInfo?.name}
                </Typography>
                {userInfo && (
                    <div>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleUserMenu}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={() => history.push('/dashboard')}>
                                Dashboard
                            </MenuItem>
                            <MenuItem onClick={() => history.push('/settings')}>
                                Settings
                            </MenuItem>
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                            {/* {user.name === "sean" && (
                                <MenuItem onClick={handleRefresh}>Logout</MenuItem>
                            )} */}
                        </Menu>
                    </div>
                )}
            </Toolbar>
        </AppBar>
    );
};
