import React, { useContext } from 'react';
import { Card, CardContent, Typography, CardActions, Button } from '@mui/material';
import Rodal from 'rodal';
import styles from './Modal.module.scss';
import { ModalContext } from '../contexts/ModalContext';

export const ConfirmationModal = ({ message, onConfirm, onCancel }) => {
    const { isModalOpen, setIsModalOpen } = useContext(ModalContext);
    return (
        <Rodal
            className={styles.modal}
            visible={isModalOpen}
            onClose={() => setIsModalOpen(false)}
        >
            <Card>
                <CardContent>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        align="left"
                    >
                        {message}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button
                        size="small"
                        color="secondary"
                        onClick={() => {
                            onConfirm?.();
                            setIsModalOpen(false);
                        }}
                    >
                        Confirm
                    </Button>
                    <Button
                        size="small"
                        color="primary"
                        onClick={() => {
                            onCancel?.();
                            setIsModalOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                </CardActions>
            </Card>
        </Rodal>
    );
};
