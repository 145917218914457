import React, { useContext, useState } from 'react';
import { ColorPicker } from 'mui-color';

import { Header } from '../Header/Header';
import styles from './Settings.module.scss';
import {
    Button,
    Card,
    CardActions,
    Typography,
    CardContent,
    Container,
    TextField,
    useTheme,
    Grid,
    hexToRgb,
} from '@mui/material';

import { UserContext } from '../contexts/UserContext';
import { ModalContext } from '../contexts/ModalContext';
import { pick } from 'lodash';
import { interpolateColors } from '../../util/colors';
import { Modal } from '../modals';

const validPaletteSwaps = [
    'primary',
    'secondary',
    'error',
    'warning',
    'success',
    'info',
];

const Selector = ({ palette, colorOverrides, setColorOverrides }) => {
    const { setIsModalOpen } = useContext(ModalContext);

    const [color1, setColor1] = useState(colorOverrides[0] || palette.primary.main);
    const [color2, setColor2] = useState(
        colorOverrides[colorOverrides.length - 1] || palette.secondary.main
    );
    return (
        <>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item>
                        <ColorPicker value={color1} onChange={setColor1} />
                    </Grid>
                    <Grid item>
                        <ColorPicker value={color2} onChange={setColor2} />
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
                <Button
                    color="secondary"
                    onClick={() => {
                        setIsModalOpen(false);
                        console.log(color1, color2);
                        const interpolatedColors = interpolateColors(
                            hexToRgb(color1?.hex ? `#${color1.hex}` : color1),
                            hexToRgb(color2?.hex ? `#${color2.hex}` : color2),
                            colorOverrides.length
                        );
                        setColorOverrides(interpolatedColors);
                    }}
                >
                    Confirm
                </Button>
            </CardActions>
        </>
    );
};

export const Settings = () => {
    const theme = useTheme();
    const { showAlert, showModal } = useContext(ModalContext);
    const { userInfo = {}, updateUserDoc } = useContext(UserContext);
    const { palette = {}, colors } = userInfo;

    const [colorOverrides, setColorOverrides] = useState(colors);

    const [paletteOverrides, setPaletteOverrides] = useState({
        ...pick(theme.palette, validPaletteSwaps),
        ...palette,
    });

    const handlePaletteChange = () => {
        const colorUpdate = Object.entries(paletteOverrides).reduce(
            (acc, [name, value]) => ({
                ...acc,
                ...(value?.main?.hex && {
                    [name]: {
                        main: `#${value.main.hex}`,
                    },
                }),
                ...(/^#[0-9A-F]{6}$/i.test(value?.main) && {
                    [name]: {
                        main: value.main,
                    },
                }),
            }),
            {}
        );
        updateUserDoc({ palette: { ...palette, ...colorUpdate } }, () =>
            showAlert({
                type: 'success',
                message: 'Successfully updated your theme.',
            })
        );
    };

    const [partnerNickname, setPartnerNickname] = useState(userInfo.partnerNickname);
    const handlePartnerNicknameChange = () => {
        updateUserDoc({ partnerNickname }, () =>
            showAlert({
                type: 'success',
                message: `Changed your partner's nickname to ${partnerNickname}`,
            })
        );
    };

    const paletteOptions = Object.entries(theme.palette).filter(([option]) =>
        validPaletteSwaps.includes(option)
    );

    const colorPickers = paletteOptions.map(([name]) => (
        <Grid item>
            <div>
                <Typography align="left">{name.toUpperCase()}</Typography>
                <ColorPicker
                    value={paletteOverrides?.[name]?.main}
                    onChange={(val) =>
                        setPaletteOverrides((curr) => ({
                            ...curr,
                            [name]: {
                                main: val,
                            },
                        }))
                    }
                />
            </div>
        </Grid>
    ));

    const handleColorsChange = () => {
        updateUserDoc(
            {
                colors: colorOverrides.map((color) =>
                    color?.hex ? `#${color.hex}` : color
                ),
            },
            () =>
                showAlert({
                    type: 'success',
                    message: 'Successfully changed your colors',
                })
        );
    };

    const prefillInterpolatedColors = () => {
        showModal(
            <Modal
                content={
                    <Selector
                        palette={palette}
                        colorOverrides={colorOverrides}
                        setColorOverrides={setColorOverrides}
                    />
                }
            />
        );
    };

    return (
        <div className={styles.settings}>
            <Header />
            <Container style={{ paddingTop: '24px' }}>
                <Card>
                    <Typography style={{ padding: '15px' }} align="left">
                        Theme Colors
                    </Typography>
                    <CardContent>
                        <Grid container spacing={3}>
                            {colorPickers}
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Button
                            disabled={
                                !Object.entries(paletteOverrides).some(
                                    ([, val]) =>
                                        val?.main?.hex ||
                                        /^#[0-9A-F]{6}$/i.test(val?.main)
                                )
                            }
                            onClick={handlePaletteChange}
                            color="secondary"
                        >
                            submit
                        </Button>
                    </CardActions>
                </Card>
                <Card>
                    <Typography style={{ padding: '15px' }} align="left">
                        Chart Colors
                    </Typography>
                    <CardContent>
                        <Grid container spacing={3}>
                            {colorOverrides.map((color, index) => (
                                <Grid item>
                                    <ColorPicker
                                        value={color}
                                        onChange={(val) =>
                                            setColorOverrides((curr) => [
                                                ...curr.slice(0, index),
                                                val,
                                                ...curr.slice(index + 1),
                                            ])
                                        }
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Button onClick={handleColorsChange} color="secondary">
                            submit
                        </Button>
                        <Button onClick={prefillInterpolatedColors}>
                            Interpolate
                        </Button>
                    </CardActions>
                </Card>
                {userInfo.partner && (
                    <Card>
                        <CardActions>
                            <CardContent>
                                <TextField
                                    id="partnerName"
                                    label="Partner Nickname"
                                    value={partnerNickname}
                                    onChange={(evt) =>
                                        setPartnerNickname(evt.target.value)
                                    }
                                    variant="outlined"
                                />
                            </CardContent>
                            <Button onClick={handlePartnerNicknameChange}>
                                submit
                            </Button>
                        </CardActions>
                    </Card>
                )}
            </Container>
        </div>
    );
};
