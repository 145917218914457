import React, { useContext, useState } from 'react';
import { TextField, Button, InputAdornment } from '@mui/material';
import { Dropdown } from '../../form/Dropdown/Dropdown';
import { omitBy, isUndefined } from 'lodash';
import { firestore } from '../../../firebase';
import styles from './AddNewCalendar.module.scss';
import { ModalContext } from '../../contexts/ModalContext';
import { Modal } from '../../modals';

export const AddNewCalendarButton = ({
    uid,
    setSelectedCalendars,
    setCalendars,
    updateUserDoc,
    calendarDefs,
}) => {
    const { showModal } = useContext(ModalContext);

    const showCalendarForm = () => {
        showModal(
            <Modal
                content={
                    <AddNewCalendar
                        uid={uid}
                        setSelectedCalendars={setSelectedCalendars}
                        setCalendars={setCalendars}
                        updateUserDoc={updateUserDoc}
                        calendarDefs={calendarDefs}
                    />
                }
            />
        );
    };

    return (
        <Button variant="contained" color="primary" onClick={showCalendarForm}>
            Add Calendar
        </Button>
    );
};

export const AddNewCalendar = ({
    uid,
    setSelectedCalendars,
    setCalendars,
    updateUserDoc,
    calendarDefs = [],
}) => {
    const [calendarName, setCalendarName] = useState('');
    const [iconName, setIconName] = useState('add');
    const [tracking, setTracking] = useState('single');
    const [group, setGroup] = useState('');
    const [unit, setUnit] = useState('');
    const trackingTypes = ['single', 'input'];

    const { showAlert } = useContext(ModalContext);

    const handleAddCalendar = () => {
        const calendarData = {
            data: [],
            tracking,
            ...omitBy({ icon: iconName, unit, group }, isUndefined),
        };
        firestore
            .collection(`users/${uid}/calendars`)
            .doc(calendarName)
            .set(calendarData)
            .then(() => {
                showAlert({
                    type: 'success',
                    message: `Added a new calendar: ${calendarName}`,
                    onConfirm: () =>
                        setSelectedCalendars((curr) => [...curr, calendarName]),
                });
            });
        setCalendars((calendars) => ({
            ...calendars,
            [calendarName]: calendarData,
        }));
        updateUserDoc({ calendarDefs: [...calendarDefs, calendarName] });
    };

    return (
        <div className={styles.addCalendar}>
            <TextField
                id="calendarName"
                label="Calendar name"
                variant="outlined"
                value={calendarName}
                size="small"
                onChange={(e) => setCalendarName(e.target.value)}
            />
            {tracking === 'input' && (
                <TextField
                    id="trackingType"
                    label="Units"
                    variant="outlined"
                    value={unit}
                    size="small"
                    onChange={(e) => setUnit(e.target.value)}
                />
            )}
            {tracking === 'single' && (
                <TextField
                    id="iconName"
                    label="Icon name"
                    variant="outlined"
                    value={iconName}
                    size="small"
                    onChange={(e) => setIconName(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <span className="material-icons">{iconName}</span>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            <Dropdown
                id="trackingInput"
                value={tracking}
                onChange={(event) => setTracking(event.target.value)}
                label="Tracking type"
                options={trackingTypes}
            />
            <TextField
                id="group"
                label="Group"
                variant="outlined"
                value={group}
                size="small"
                onChange={(e) => setGroup(e.target.value)}
            />
            <Button variant="contained" color="primary" onClick={handleAddCalendar}>
                Add Calendar
            </Button>
        </div>
    );
};
