import { firestore } from '../firebase';

export const queryUserCollections = ({ collection, uid, onSuccess }) => {
    firestore
        .collection(`users/${uid}/${collection}`)
        .get()
        .then((subCollectionSnapshot) => {
            const docs = [];
            subCollectionSnapshot.forEach((subDoc) => {
                docs.push(subDoc);
            });
            onSuccess(docs);
        })
        .catch((err) => console.log(err));
};

export const updateUserCollection = ({
    collection,
    doc,
    uid,
    update,
    onSuccess,
}) => {
    firestore
        .collection(`users/${uid}/${collection}`)
        .doc(doc)
        .update(update)
        .then(onSuccess)
        .catch((err) => console.log(err));
};

export const queryUser = ({ uid, onSuccess }) => {
    firestore
        .collection('users')
        .doc(uid)
        .get()
        .then((doc) => {
            onSuccess(doc);
        });
};
