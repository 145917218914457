import React, { useContext } from 'react';
import { Card } from '@mui/material';
import Rodal from 'rodal';
import styles from './Modal.module.scss';
import { ModalContext } from '../contexts/ModalContext';
import cn from 'classnames';

export const Modal = ({ className, content }) => {
    const { isModalOpen, setIsModalOpen } = useContext(ModalContext);

    return (
        <Rodal
            className={cn(styles.modal, className)}
            visible={isModalOpen}
            onClose={() => setIsModalOpen(false)}
        >
            <Card>{content}</Card>
        </Rodal>
    );
};
