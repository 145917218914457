import React, { createContext, useState, useEffect, useContext } from 'react';
import { auth, firestore } from '../../firebase';
import { ModalContext } from './ModalContext';

export const UserContext = createContext({});

export const UserContextProvider = ({ children }) => {
    const { currentUser = {} } = auth;
    const { showAlert } = useContext(ModalContext);

    const [context, setContext] = useState({});

    useEffect(() => {
        if (currentUser) {
            const { email, uid } = currentUser;
            firestore
                .collection('users')
                .doc(uid)
                .get()
                .then((userDoc) => {
                    const { partner } = userDoc.data();
                    if (partner) {
                        firestore
                            .collection('users')
                            .doc(partner)
                            .get()
                            .then((partnerDoc) => {
                                setContext({
                                    uid,
                                    userInfo: userDoc.data(),
                                    email,
                                    userToShow: uid,
                                    partnerInfo: partnerDoc.data(),
                                    currentUserInfo: userDoc.data(),
                                });
                            });
                    } else {
                        setContext({
                            uid,
                            userInfo: userDoc.data(),
                            email,
                            userToShow: uid,
                            currentUserInfo: userDoc.data(),
                        });
                    }
                });
        }
    }, [currentUser]);

    const updateUserDoc = (info, onSuccess) => {
        firestore
            .collection('users')
            .doc(currentUser.uid)
            .update(info)
            .then(() => {
                onSuccess?.();
                setContext((curr) => ({
                    ...curr,
                    userInfo: { ...curr.userInfo, ...info },
                    ...(curr.userToShow === currentUser.uid && {
                        currentUserInfo: { ...curr.currentUserInfo, ...info },
                    }),
                }));
            })
            .catch((err) => {
                showAlert({
                    type: 'error',
                    message: 'Failed to update user info',
                });
                console.error(err);
            });
    };

    const toggleUserToShow = () => {
        const { uid } = currentUser;
        const otherUser =
            context.userToShow === uid ? context.userInfo.partner : uid;
        const otherUserInfo =
            context.userToShow === uid ? context.partnerInfo : context.userInfo;
        setContext((curr) => ({
            ...curr,
            userToShow: otherUser,
            currentUserInfo: otherUserInfo,
        }));
    };

    return (
        <UserContext.Provider
            value={{ ...context, updateUserDoc, toggleUserToShow }}
        >
            {children}
        </UserContext.Provider>
    );
};
