import React, { createContext, useCallback, useEffect, useState } from 'react';

export const BreakpointContext = createContext({
    breakpoint: 'desktop',
    width: window.outerWidth,
});

const getBreakpoint = () => {
    const width = window.outerWidth;
    if (width < 560) {
        return 'mobile';
    } else if (width < 900) {
        return 'tablet';
    }
    return 'desktop';
};

export const BreakpointContextProvider = ({ children }) => {
    const [breakpoint, setBreakpoint] = useState(getBreakpoint());
    const [width, setWidth] = useState(window.innerWidth);

    const onWindowResize = useCallback(() => {
        setBreakpoint(getBreakpoint());
        setWidth(window.innerWidth);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', onWindowResize);

        return () => window.removeEventListener('resize', onWindowResize);
    }, [onWindowResize]);

    return (
        <BreakpointContext.Provider value={{ breakpoint, width }}>
            {children}
        </BreakpointContext.Provider>
    );
};
