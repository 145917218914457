import React, { useContext } from 'react';
import {
    Card,
    CardContent,
    Typography,
    CardActions,
    Button,
    useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Rodal from 'rodal';
import styles from './Modal.module.scss';
import { ModalContext } from '../contexts/ModalContext';
import cn from 'classnames';

const iconMap = {
    success: 'check_circle',
    info: 'info',
    warning: 'warning_amber',
    error: 'error_outline',
};

export const AlertModal = ({ message, onConfirm, type = 'warning' }) => {
    const theme = useTheme();
    const { isModalOpen, setIsModalOpen } = useContext(ModalContext);

    const useStyles = makeStyles((theme) => ({
        button: {
            color: theme.palette?.[type]?.main,
        },
    }));
    const classes = useStyles();

    return (
        <Rodal
            className={cn(styles.modal, styles.alert)}
            visible={isModalOpen}
            onClose={() => setIsModalOpen(false)}
        >
            <Card
                variant="outlined"
                style={{ borderColor: theme.palette?.[type]?.main }}
            >
                <span className="material-icons">{iconMap[type]}</span>
                <CardContent>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        align="left"
                    >
                        {message}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button
                        className={classes.button}
                        size="small"
                        onClick={() => {
                            onConfirm?.();
                            setIsModalOpen(false);
                        }}
                    >
                        Ok
                    </Button>
                </CardActions>
            </Card>
        </Rodal>
    );
};
