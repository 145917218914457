import React, { useContext } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { linearGradientDef } from '@nivo/core';
import { Paper } from '@mui/material';
import {get} from 'lodash';
import { BreakpointContext } from '../../contexts/BreakpointContext';

type CustomSymbolProps = {
    size: number;
    color: string;
    borderWidth: number;
    borderColor: string;
}

const CustomSymbol: React.FC<CustomSymbolProps> = ({ size, color, borderWidth, borderColor }) => (
    <g>
        <circle r={size / 2} strokeWidth={borderWidth} stroke={borderColor} />
        <circle
            r={size / 5}
            strokeWidth={borderWidth}
            stroke={borderColor}
            fill={color}
            fillOpacity={0.35}
        />
    </g>
);

type DataPoint = {
    id: string,
    data: {
        x: string;
        y: number;
    }[]
}

type LineChartProps = {
    data: DataPoint[];
    colors: string[];
}

export const LineChart: React.FC<LineChartProps> = ({ data = [], colors }) => {
    const { width } = useContext(BreakpointContext);
    const idealTicks = Math.floor(width / 100);
    const longestData = Math.max(...data.map(({ data }) => data.length), 0);
    const numTicks = idealTicks > longestData ? longestData : idealTicks;
    const common = {
        margin: { top: 20, right: 100, bottom: 60, left: 40 },
        animate: true,
        enableSlices: 'x' as const,
        useMesh: true,
    };

    return (
        <ResponsiveLine
            {...common}
            colors={colors}
            theme={{
                textColor: '#fff',
                axis: { ticks: { text: { fontSize: 14 } } },
                grid: { line: { stroke: 'white', strokeDasharray: '1 2' } },
                crosshair: { line: { stroke: 'white' } },
            }}
            data={data}
            xScale={{
                type: 'time',
                format: '%Y-%m-%d',
                useUTC: false,
                precision: 'day',
            }}
            xFormat="time:%Y-%m-%d"
            yScale={{
                type: 'linear',
                stacked: false,
            }}
            axisLeft={{
                tickValues: 5,
            }}
            axisBottom={{
                format: '%b %d',
                tickValues: numTicks,
            }}
            curve="monotoneX"
            // tooltip={({ point }) => (
            //     <Paper elevation={7} style={{ padding: '15px' }} align="left">
            //         <div style={{ color: point.serieColor }}>{point.serieId}</div>
            //         <div>{point.data.x.toLocaleDateString('en-US')}</div>
            //         <div>value: {point.data.y}</div>
            //     </Paper>
            // )}
            sliceTooltip={({ slice }) => {
                return (
                    <Paper elevation={7} style={{ padding: '15px' }}>
                        <div>{get(slice, 'points.0.data.xFormatted')}</div>
                        {slice.points.map((point) => (
                            <div key={point.id}>
                                <div style={{ color: point.serieColor }}>
                                    {point.serieId}
                                </div>

                                <div>{`value: ${point.data.y}`}</div>
                            </div>
                        ))}
                    </Paper>
                );
            }}
            pointSymbol={CustomSymbol}
            pointSize={16}
            pointBorderWidth={1}
            pointBorderColor={{
                from: 'color',
                modifiers: [['darker', 0.3]],
            }}
            enableGridX={false}
            enableGridY={false}
            enableArea={true}
            defs={[
                linearGradientDef('gradientA', [
                    { offset: 0, color: 'inherit' },
                    { offset: 100, color: 'inherit', opacity: 0 },
                ]),
            ]}
            fill={[{ match: '*', id: 'gradientA' }]}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1,
                            },
                        },
                    ],
                },
            ]}
        />
    );
};
