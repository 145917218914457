import './App.css';
import 'rodal/lib/rodal.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Dashboard, SignIn, Settings } from './components';
import { auth } from './firebase';
import { UserContextProvider } from './components/contexts/UserContext';
import { ThemeProvider } from './components/contexts/ThemeContext';
import { ModalProvider } from './components/contexts/ModalContext';
import { BreakpointContextProvider } from './components/contexts/BreakpointContext';

const MainProvider = ({ children }) => (
    <BreakpointContextProvider>
        <ThemeProvider>
            <ModalProvider>{children}</ModalProvider>
        </ThemeProvider>
    </BreakpointContextProvider>
);

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
    const { currentUser } = auth;

    return (
        <Route
            {...rest}
            render={({ location }) =>
                currentUser ? (
                    <UserContextProvider>
                        <MainProvider>{children}</MainProvider>
                    </UserContextProvider>
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}

function App() {
    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route exact path="/">
                        <MainProvider>
                            <SignIn />
                        </MainProvider>
                    </Route>
                    <PrivateRoute path="/dashboard">
                        <Dashboard />
                    </PrivateRoute>
                    <PrivateRoute path="/settings">
                        <Settings />
                    </PrivateRoute>
                </Switch>
            </Router>
        </div>
    );
}

export default App;
