import React from 'react';
import {
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    ListSubheader,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { get, isObject } from 'lodash';

export const Dropdown = ({
    id,
    label,
    variant = 'outlined',
    options = [],
    value,
    size = 'small',
    onChange,
    formatOption,
    showAll,
    fullWidth,
}) => {
    // sort so groups appear at the bottom, alphabetically
    options.sort((a, b) => {
        const aGroup = get(a, 'group', '');
        const bGroup = get(b, 'group', '');
        const aName = get(a, 'name', a);
        const bName = get(b, 'name', b);

        const aComp = aGroup ? `zzz${aGroup}${aName}` : aName;
        const bComp = bGroup ? `zzz${bGroup}${bName}` : bName;
        return aComp.localeCompare(bComp);
    });

    const useStyles = makeStyles((theme) => ({
        showAll: {
            color: theme.palette?.secondary?.main,
        },
        menuPaper: {
            maxHeight: '300px',
        },
        '@global': {
            '*::-webkit-scrollbar': {
                width: '0.4em',
            },
            '*::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.78)',
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette?.secondary?.main,
                outline: '1px solid slategrey',
            },
        },
    }));
    const classes = useStyles();

    return (
        <FormControl variant={variant} size={size} fullWidth={fullWidth}>
            <InputLabel id={label}>{label}</InputLabel>
            <Select
                labelId={`${id}-label`}
                id={id}
                value={value}
                onChange={onChange}
                label={label}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
            >
                {options.map((option, index, array) => {
                    if (isObject(option)) {
                        if (option.group) {
                            const components = [];
                            if (option.group !== get(array, index - 1)?.group) {
                                components.push(
                                    <ListSubheader disableSticky>
                                        {option.group}
                                    </ListSubheader>
                                );
                            }
                            components.push(
                                <MenuItem key={option.name} value={option.value}>
                                    {formatOption?.(option) || option.name}
                                </MenuItem>
                            );
                            return components;
                        }
                        return (
                            <MenuItem key={option.name} value={option.value}>
                                {formatOption?.(option) || option.name}
                            </MenuItem>
                        );
                    }
                    return (
                        <MenuItem key={option} value={option}>
                            {formatOption?.(option) || option}
                        </MenuItem>
                    );
                })}
                {showAll && (
                    <MenuItem
                        key="show all"
                        value="show all"
                        className={classes.showAll}
                    >
                        show all
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    );
};
