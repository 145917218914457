import { useState, useEffect, useContext, useCallback } from 'react';
import cn from 'classnames';
import { Button, useTheme, IconButton, TextField } from '@mui/material';
import { styled } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import styles from './Dashboard.module.scss';
import { CalendarChart } from './CalendarChart/CalendarChart';
import { Header } from '../Header/Header';
import { isEmpty, isUndefined, uniq } from 'lodash';
import { Dropdown } from '../form/Dropdown/Dropdown';
import { InputField } from '../form/InputField/InputField';
import { UserContext } from '../contexts/UserContext';
import { convertToFormattedDate } from '../../util/format';

import { queryUserCollections, updateUserCollection } from '../../util/api';
import { LineChart } from './LineChart/LineChart';
import { ModalContext } from '../contexts/ModalContext';
import { BreakpointContext } from '../contexts/BreakpointContext';
import { usePrevious } from '../../hooks/usePrevious';
import { Sidebar } from './Sidebar/Sidebar';

const calenderIconMap = {
    workouts: 'fitness_center',
    medication: 'medication',
};

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        //   padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    })
);

const AppBar = styled(Header, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export const Dashboard = () => {
    const { breakpoint } = useContext(BreakpointContext);
    const { showConfirm } = useContext(ModalContext);

    const {
        userToShow,
        toggleUserToShow,
        userInfo,
        uid,
        currentUserInfo = {},
        updateUserDoc,
    } = useContext(UserContext);
    const [calendars, setCalendars] = useState({});
    const [selectedCalendars, setSelectedCalendars] = useState([]);

    const [calendarInputs, setCalendarInputs] = useState({});
    const handleInputChange = (input, calendar) => {
        setCalendarInputs((inputs) => ({
            ...inputs,
            [calendar]: input,
        }));
    };

    const getCalendarDataAtDate = (calendar, date) => {
        return calendars?.[calendar]?.data?.find(
            ({ day }) => day === convertToFormattedDate(date)
        );
    };

    const [dates, setDates] = useState({});
    const handleDateChange = (date, calendar) => {
        setDates((dates) => ({
            ...dates,
            [calendar]: date,
        }));

        const currentCalendarData = getCalendarDataAtDate(calendar, date);
        if (currentCalendarData?.value) {
            handleInputChange(currentCalendarData.value, calendar);
        }
    };

    useEffect(() => {
        const inputUpdates = {};
        const dateUpdates = {};

        Object.keys(calendars).forEach((calendar) => {
            const data = getCalendarDataAtDate(calendar, new Date())?.value;
            if (data) {
                inputUpdates[calendar] = data;
            } else {
                inputUpdates[calendar] = 0;
            }

            if (!dates[calendar]) {
                dateUpdates[calendar] = new Date();
            }
        });

        if (!isEmpty(inputUpdates)) {
            setCalendarInputs((curr) => ({ ...curr, ...inputUpdates }));
        }

        if (!isEmpty(dateUpdates)) {
            setDates((curr) => ({ ...curr, ...dateUpdates }));
        }

        // eslint-disable-next-line
    }, [calendars]);

    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    const [fromDate, setFromDate] = useState(oneMonthAgo);
    const [toDate, setToDate] = useState(new Date());

    const updateCalendars = useCallback(() => {
        if (userToShow) {
            queryUserCollections({
                uid: userToShow,
                collection: 'calendars',
                onSuccess: (docs) => {
                    const userCalendars = docs.reduce(
                        (acc, item) => ({ ...acc, [item.id]: item.data() }),
                        {}
                    );
                    setCalendars(userCalendars);
                    const [firstCalendar] = Object.keys(userCalendars);
                    if (firstCalendar) {
                        setSelectedCalendars([firstCalendar]);
                    } else {
                        setSelectedCalendars([]);
                    }
                },
            });
        }
    }, [userToShow]);

    useEffect(() => {
        updateCalendars();
    }, [userToShow, updateCalendars]);

    useEffect(() => {
        if (isEmpty(selectedCalendars) && !isEmpty(calendars)) {
            setSelectedCalendars([Object.keys(calendars)[0]]);
        }
    }, [calendars, selectedCalendars]);

    const handleCalendarAction = (calendar, value = 1) => {
        const day = convertToFormattedDate(dates[calendar]);
        const newEntry = {
            day,
            value,
        };
        const oldData = calendars[calendar].data;
        const newData = [...oldData.filter((point) => point.day !== day), newEntry];

        updateUserCollection({
            uid: userToShow,
            collection: 'calendars',
            doc: calendar,
            update: {
                data: newData,
            },
            onSuccess: () => {
                setCalendars((curr) => ({
                    ...curr,
                    [calendar]: {
                        ...curr[calendar],
                        data: newData,
                    },
                }));
            },
        });
    };

    const handleRemoveData = (calendar) => {
        const day = convertToFormattedDate(dates[calendar]);
        const oldData = calendars[calendar].data;
        const newData = [...oldData.filter((point) => point.day !== day)];

        updateUserCollection({
            uid: userToShow,
            collection: 'calendars',
            doc: calendar,
            update: {
                data: newData,
            },
            onSuccess: () => {
                setCalendars((curr) => ({
                    ...curr,
                    [calendar]: {
                        ...curr[calendar],
                        data: newData,
                    },
                }));
            },
        });
    };

    const theme = useTheme();

    // group lines by unit type
    const unitOptions = uniq(
        Object.values(calendars).map(({ unit }) => unit)
    ).filter((unit) => !isUndefined(unit));
    const [selectedUnit, setSelectedUnit] = useState('none');
    const lineData = Object.entries(calendars)
        .filter(
            ([, { tracking, unit }]) =>
                tracking === 'input' &&
                (unit === selectedUnit || (!unit && selectedUnit === 'none'))
        )
        .map(([name, { data }]) => ({
            id: name,
            data: data
                .filter(
                    ({ day }) => new Date(day) >= fromDate && new Date(day) <= toDate
                )
                .map(({ day, value }) => ({ x: day, y: value }))
                .sort((a, b) => new Date(a.x) - new Date(b.x)),
        }));

    const [isDrawerOpen, setDrawerOpen] = useState(
        breakpoint === 'mobile' ? false : true
    );

    const previousBreakpoint = usePrevious(breakpoint);
    useEffect(() => {
        if (breakpoint === 'mobile' && previousBreakpoint !== 'mobile') {
            setDrawerOpen(false);
        }
    }, [breakpoint, previousBreakpoint]);

    const onClickMenu = () => {
        setDrawerOpen(!isDrawerOpen);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <AppBar open={isDrawerOpen} onClickMenu={onClickMenu} />
            <Sidebar
                isDrawerOpen={isDrawerOpen}
                breakpoint={breakpoint}
                onClickMenu={onClickMenu}
                userToShow={userToShow}
                drawerWidth={drawerWidth}
                selectedCalendars={selectedCalendars}
                setSelectedCalendars={setSelectedCalendars}
                setCalendars={setCalendars}
                currentUserInfo={currentUserInfo}
                updateUserDoc={updateUserDoc}
            />

            <Main open={isDrawerOpen}>
                <div className={cn(styles.dash, { [styles.open]: isDrawerOpen })}>
                    {userInfo?.partner && (
                        <div className={styles.partners}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={toggleUserToShow}
                            >{`Go to ${
                                uid === userToShow
                                    ? userInfo.partnerNickname || 'partner'
                                    : 'me'
                            }`}</Button>
                            <img src="/img/mochi/squishyloop.gif" alt="" />
                        </div>
                    )}

                    {selectedCalendars.map((calendar) => {
                        const currentCalendarData = calendars?.[
                            calendar
                        ]?.data?.find(
                            ({ day }) =>
                                day ===
                                convertToFormattedDate(dates[calendar] || new Date())
                        );
                        return (
                            <div key={calendar} className={styles.charts}>
                                <div className={styles.chartTitle}>{calendar}</div>
                                <div className={styles.chartContainer}>
                                    <div
                                        className={cn(
                                            styles.chart,
                                            styles[breakpoint]
                                        )}
                                    >
                                        <CalendarChart
                                            unit={calendars?.[calendar]?.unit}
                                            data={calendars?.[calendar]?.data}
                                            borderColor={
                                                theme.palette.background.default
                                            }
                                            onClick={({ date }) =>
                                                handleDateChange(date, calendar)
                                            }
                                        />
                                    </div>
                                </div>

                                <div className={styles.form}>
                                    <DatePicker
                                        size="small"
                                        margin="normal"
                                        id={`date-picker-dialog_${calendar}`}
                                        label="Date"
                                        format="MM/dd/yyyy"
                                        value={dates[calendar]}
                                        onChange={(date) =>
                                            handleDateChange(date, calendar)
                                        }
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        showTodayButton
                                        renderInput={(params) => (
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                {...params}
                                            />
                                        )}
                                    />

                                    {calendars?.[calendar]?.tracking === 'input' ? (
                                        <>
                                            <InputField
                                                id={`calendarInput_${calendar}`}
                                                type="number"
                                                label="Value"
                                                value={calendarInputs[calendar]}
                                                onChange={(event) =>
                                                    handleInputChange(
                                                        event.target.value,
                                                        calendar
                                                    )
                                                }
                                            />
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() =>
                                                    handleCalendarAction(
                                                        calendar,
                                                        calendarInputs[calendar]
                                                    )
                                                }
                                            >
                                                Confirm
                                            </Button>
                                        </>
                                    ) : (
                                        <div className={styles.item}>
                                            <IconButton
                                                onClick={() =>
                                                    handleCalendarAction(calendar, 1)
                                                }
                                            >
                                                <span className="material-icons">
                                                    {calendars?.[calendar]?.icon ||
                                                        calenderIconMap[calendar] ||
                                                        'add'}
                                                </span>
                                            </IconButton>
                                        </div>
                                    )}
                                    {currentCalendarData && (
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() =>
                                                showConfirm({
                                                    message: `Are you sure you want to
                                                clear the data for "${calendar}" on ${dates[
                                                        calendar
                                                    ].toLocaleDateString('en-US', {
                                                        weekday: 'long',
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric',
                                                    })}?`,
                                                    onConfirm: () =>
                                                        handleRemoveData(calendar),
                                                })
                                            }
                                        >
                                            Clear
                                        </Button>
                                    )}
                                </div>
                            </div>
                        );
                    })}

                    <div
                        style={{
                            display: 'flex',
                            padding: '16px',
                            gap: '10px',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}
                    >
                        <Dropdown
                            id="unitSelect"
                            value={selectedUnit}
                            onChange={(event) => setSelectedUnit(event.target.value)}
                            label="Line unit"
                            options={[...unitOptions, 'none']}
                        />
                        <DatePicker
                            size="small"
                            margin="normal"
                            id="date-picker-dialog"
                            label="From"
                            format="MM/dd/yyyy"
                            value={fromDate}
                            onChange={setFromDate}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            showTodayButton
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    {...params}
                                />
                            )}
                        />
                        <DatePicker
                            size="small"
                            margin="normal"
                            id="date-picker-dialog"
                            label="To"
                            format="MM/dd/yyyy"
                            value={toDate}
                            onChange={setToDate}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            showTodayButton
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    {...params}
                                />
                            )}
                        />
                    </div>
                    {!isEmpty(lineData) &&
                        lineData.some((entry) => !isEmpty(entry?.data)) && (
                            <div className={styles.line}>
                                <LineChart
                                    data={lineData}
                                    colors={currentUserInfo.colors}
                                />
                            </div>
                        )}
                </div>
            </Main>
        </LocalizationProvider>
    );
};
