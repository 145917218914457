import React, { useContext } from 'react';
import {
    ThemeProvider as MuiThemeProvider,
    createTheme,
} from '@mui/material/styles';
import { UserContext } from './UserContext';
import { CssBaseline } from '@mui/material';

export const ThemeProvider = ({ children }) => {
    const { currentUserInfo = {}, userInfo = {} } = useContext(UserContext);
    const paletteOverrides = currentUserInfo.palette || userInfo.palette;
    const theme = createTheme({
        palette: {
            mode: 'dark',
            // primary: { main: '#903749' },
            // secondary: { main: '#53354a' },
            // // common: {
            // //     black: {
            // //         main: '#2b2e4a',
            // //     },
            // //     white: {
            // //         main: '#2b2e4a',
            // //     },
            // // },
            // background: {
            //     default: '#0d1117',
            //     paper: '#161b22',
            // },
            // error: { main: '#f44336' },
            // warning: { main: '#ff9800' },
            // info: { main: '#2196f3' },
            // success: { main: '#4caf50' },
            // // error: 'red',
            // // Used by `getContrastText()` to maximize the contrast between the background and
            // // the text.
            // contrastThreshold: 3,
            // // Used to shift a color's luminance by approximately
            // // two indexes within its tonal palette.
            // // E.g., shift from Red 500 to Red 300 or Red 700.
            // tonalOffset: 0.2,
            ...paletteOverrides,
        },
    });

    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </MuiThemeProvider>
    );
};
